<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
      <tr>
        <slot name="columns">
          <th v-for="(column, index) in columnth" :key="column">
            <button class="sorttable" @click="SortTable(columns[index])">
              {{ fomatColum(column) }}
              <i
                v-show="sort.col != column && (sort.even == 'desc' || sort.even == 'aec')"
                class="fa-solid fa-sort"
              ></i>
              <i
                v-show="sort.col == column && sort.even == 'desc'"
                class="fa-solid fa-sort-up"
              ></i>
              <i
                v-show="sort.col == column && sort.even == 'aec'"
                class="fa-solid fa-sort-down"
              ></i>
            </button>
          </th>
        </slot>
      </tr>
    </thead>
    <tbody :class="tbodyClasses">
      <template v-if="data.length > 0">
        <tr v-for="(item, index) in data" :key="index" :class="item.type">
          <slot :row="item">
            <td
              v-for="(column, index) in columns"
              :key="index"
              v-if="hasValue(item, column)"
              v-html="itemValue(item, column)"
            ></td>
          </slot>
        </tr>
      </template>
      <template v-else>
        <tr>
          <td :colspan="columns.length" class="text-center">
            ไม่มีรายการ
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>
<script>
import config from '@/config.js';
import utils from "@/utils";
export default {
  name: "base-table-expried7d",
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: "Table columns",
    },
    columnth: {
      type: Array,
      default: () => [],
      description: "Table columns",
    },
    sort: {
      type: Object,
      default: () => {},
    },
    data: {
      type: Array,
      default: () => [],
      description: "Table data",
    },
    title: {
      type: String,
      default: "รายการ",
    },
    type: {
      type: String, // striped | hover
      default: "",
      description: "Whether table is striped or hover type",
    },
    theadClasses: {
      type: String,
      default: "",
      description: "<thead> css classes",
    },
    tbodyClasses: {
      type: String,
      default: "",
      description: "<tbody> css classes",
    },
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
  },
  methods: {
    formatDate(timestamp) {
      let timeS = timestamp ? new Date(timestamp) : new Date();
      let d = timeS,
        hour = d.getHours(),
        minutes = d.getMinutes(),
        seconds = d.getSeconds(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return `${[year, month, day].join("-")} ${hour}:${minutes}:${seconds}`;
    },
    fomatColum(column) {
      return column; //this.title[column];
    },
    isValidDate(d) {
      return d instanceof Date && !isNaN(d);
    },
    hasValue(item, column) {
      return item[column] !== "undefined";
    },
    itemValue(item, column) {
      if (column == "created_at" || column == "updated_at") {
        return utils.forMatDateTimes(new Date(item[column]));
      } else if (column == "refer") {
        return `${item.refer.col}-${item.refer.id}`;
      } else if (column == "latestTransaction.created_at") {
        return utils.forMatDateTimes(new Date(item.latestTransaction.created_at));
      } else if(column == "numberBank"){
        return `<img class="icons-sys" src="${config.iconBank[item.typeBank]}" /> ${item.numberBank}`;
      } else if(column == "deposit"){
        const formattedCurrency = new Intl.NumberFormat('th-TH', {
          style: 'currency',
          currency: 'THB',
        }).format(item.deposit);
        return formattedCurrency;
      } else if(column == "status"){
        if(item.status == 1){
          return '<span class="badge badge-success">เปิดใช้งาน</span>';
        }
        else{
          return '<span class="badge badge-danger">ปิดใช้งาน</span>';
        }
      }else if(column == "latestTransaction.event"){
      if(item.latestTransaction.event == "deposit"){
        return `<span class="badge badge-success">ฝาก</span>`;
      }
      else if(item.latestTransaction.event == "withdraw"){
        return `<span class="badge badge-danger">ถอน</span>`;
      }
      else if(item.latestTransaction.event == "lose"){
        return `<span class="badge badge-warning">ยอดเสีย</span>`;
      }
      else if(item.latestTransaction.event == "transfers"){
        return `<span class="badge badge-info">โยกกระเป๋า</span>`;
      }
      else if(item.latestTransaction.event == "modify"){
        return `<span class="badge badge-dark">ปรับยอดโดยแอดมิน</span>`;
      }
      else if(item.latestTransaction.event == "usepro"){
        return `<span class="badge badge-secondary">ใช้โปร</span>`;
      }
      else if(item.latestTransaction.event == "wheel"){
        return `<span class="badge badge-primary">วงล้อ</span>`;
      }
      else{
        return item.latestTransaction.event;
      }
    } else {
        return item[column];
      }
    },
    SortTable(column) {
      this.$emit("SortTable", column);
    },
  },
};
</script>
<style scoped>
.deposit {
  background: rgb(45 206 137 / 30%) !important;
}
.withdraw {
  background: rgb(245 54 92 / 30%) !important;
}
</style>
