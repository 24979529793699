<template>
  <div class="row">
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <div class="col-12">
      <card :title="table.title" v-if="dataItem.isPage == 'table'">
        <div class="row">
          <div class="col-9">
            <div class="row">
              <div class="col-6">
                <base-input label="ค้นหา" type="taxt" v-model="from.search"></base-input>
              </div>
              <div class="col-3">
                <base-input label="เริ่ม" type="date" v-model="from.date.start"></base-input>
              </div>
              <div class="col-3">
                <base-input label="สิ้นสุด" type="date" v-model="from.date.end"></base-input>
              </div>
            </div>
          </div>
          <div class="col-3">
            <base-button type="secondary" size="md" class="pull-right ml-3 mt-3" @click="Search()"> <i class="tim-icons icon-zoom-split"></i> </base-button>
            <base-button type="warning" size="md" class="pull-right ml-3 mt-3" @click="Download()"> <i class="tim-icons icon-cloud-download-93"></i> </base-button>
            <base-button type="info" size="md" class="pull-right ml-3 mt-3" @click="Backpage()"> <i class="tim-icons icon-bullet-list-67"></i> </base-button>
          </div>
        </div>
        <div class="table-responsive">
          <table-list-af :data="table.data" :columns="table.columns" :columnth="table.columnsTH" 
            thead-classes="text-primary" />
        </div>
      </card>
    </div> 
  </div>
</template>
<script>
import { TableListAf, Modal, TextArea } from "@/components";
// Import component
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
/////
import axios from 'axios';
import utils from '@/utils';
import config from '@/config.js';
const tableColumns = ["created_at", "ip", "deposit", "name"];
const tableColumnsTH = ["วันที่", "ยูสเซอร์ / ip", "ฝาก", "สถานะ"];
const tableData = [];
///
let toDateInputValue = (dateObject)=>{
    const local = new Date(dateObject);
    local.setMinutes(dateObject.getMinutes() - dateObject.getTimezoneOffset());
    return local.toJSON().slice(0,10);
};
//
export default {
  components: {
    TableListAf,
    TextArea,
    Loading,
    Modal
  },
  data() {
    return {
      table: {
        title: "รายการ",
        columns: [...tableColumns],
        columnsTH: [...tableColumnsTH],
        data: [...tableData]
      }, 
      from:{
        search: this.$route.params.id,
        date:{
          start: toDateInputValue(new Date()),
          end: toDateInputValue(new Date()),
        }
      },
      dataItem: { isPage: 'table' },
      isLoading: true,
      fullPage: true,
      
    };
  },
  methods: {
    LoadData(dataform) {
      this.isLoading = true;
      axios(config.api('post', '/shortLink/regis',dataform)).then((response) => {
        this.table.data = this.theCombineData({members:response.data.ResultData.members,list:response.data.ResultData.list});
        this.isLoading = false; 
      }, (error) => {
        this.isLoading = false;
        this.$alert(error.response.data.Message, 'Error', 'error');
      });
    },
    Search(){
      this.LoadData({tag:this.from.search,start:this.from.date.start,end:this.from.date.end});
    },
    theCombineData(data){
      const memberCount = data.members.length;
      const listDataCount = data.list.data.length;
      const filteredListData =
        listDataCount > memberCount
          ? data.list.data.slice(memberCount)
          : data.list.data;
      const combinedData = [...data.members, ...filteredListData];
      return combinedData;
    },
    Download(){
      if(this.table.data.length){
        this.isLoading = true;
        axios(config.api('get', `/shortLink/regis/${this.from.search}/${this.from.date.start}/${this.from.date.start}`)).then((response) => { 
          window.open(`${config.apiplayload}/${response.data.ResultData}`,'_blank');
          this.isLoading = false; 
        }, (error) => {
          this.isLoading = false;
          this.$alert(error.response.data.Message, 'Error', 'error');
        });
      }else{
        this.$alert('ยังไม่มีข้อมูล', 'ข้อความ', 'warning');
      }
    },
    Backpage(){
      this.$router.push(`/AFLink`);
    }
  },
  mounted() {
    this.LoadData({tag:this.from.search,start:this.from.date.start,end:this.from.date.end});
    // this.isLoading = false;
    // console.log('params',this.$route.params.id);
    
  }
};
</script>
<style></style>
