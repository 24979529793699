import utils from '@/utils';
import Banks from './Bank.json';
import Memnu from './memnu';
let apiplayload = 'http://localhost:9879';
let Static = 'https://static.stonecold911.com';
export default {
  level: ['god', 'seller', 'senior', 'master', 'agent', 'admin'],
  domain: 'https://www.stonecold911.com',
  apiplayload: apiplayload,
  urlstatic: Static,
  colors: {
    default: "#344675",
    primary: "#42b883",
    info: "#1d8cf8",
    danger: "#fd5d93",
    teal: "#00d6b4",
    primaryGradient: ['rgba(76, 211, 150, 0.1)', 'rgba(53, 183, 125, 0)', 'rgba(119,52,169,0)'],
  },
  ws: apiplayload,
  image: (data) => {
    return {
      method: 'POST',
      url: `${Static}/images`,
      headers: {
        'Content-Type': 'application/json',
        'domain': utils.getDomain(),
      },
      data: data
    }
  },
  static: (method, enpoint, data = '') => {
    if (method == 'POST' || method == 'post') {
      return {
        method: 'POST',
        url: `${Static}${enpoint}`,
        headers: {
          'Content-Type': 'application/json',
          'domain': utils.getDomain(),
        },
        data: data
      }
    } else {
      return {
        method: 'GET',
        url: `https://static.stonecold911.com${enpoint}`,
        headers: {
          'Content-Type': 'application/json',
          'domain': utils.getDomain(),
        }
      }
    }
  },
  api: (method, enpoint, data, ipify, permis) => {
    var data = JSON.stringify(data);
    let token = localStorage.getItem('token') || '';
    let permission = permis || 'pass';
    let ipifys = ipify || null;
    if (localStorage.getItem('token')) {
      // console.log(`${method}->https://resapi.dummy212.com/bof/api/v1${enpoint}`);
      return {
        method: method,
        url: `${apiplayload}/bof/api/v1${enpoint}`, ////  xogame44
        headers: {
          'api-key': 'Ce9rKVJKtmKFDfsSCY3ZbK3kNFzYn8RA',
          'Authorization': `Bearer ${token}`,
          'permis': permission,
          'domain': null,
          'ipify': ipifys,
          'Content-Type': 'application/json'
        },
        data: data
      }
    } else {
      // console.log(`${method}->https://resapi.dummy212.com/bof/api/v1${enpoint}`);
      return {
        method: method,
        url: `${apiplayload}/bof/api/v1${enpoint}`, ////  xogame44
        headers: {
          'api-key': 'Ce9rKVJKtmKFDfsSCY3ZbK3kNFzYn8RA',
          'domain': null,
          'ipify': ipifys,
          'Content-Type': 'application/json'
        },
        data: data
      }
    }
  },
  iconBank: Banks.iconBank,
  nameBank: Banks.nameBank,
  sysBank: Banks.sysBank,
  selectBank: Banks.selectBank,
  memnu: Memnu
}
