<template>
    <table class="table tablesorter" :class="tableClass">
      <thead :class="theadClasses">
      <tr>
        <slot name="columns">
          <th> ลำดับ </th>
          <th v-for="(column,index) in columns" :key="column">{{ columnth[index] }}</th> 
        </slot>
      </tr>
      </thead>
      <tbody :class="tbodyClasses">
      <tr v-for="(item, index) in data" :key="index">
        <slot :row="item">
          <td>{{ index+1 }}</td>
          <td v-for="(column, index) in columns" :key="index"> 
            <div v-if="column != 'eve'" v-html="itemValue(item, column)"> </div>  
            <ul v-if="column == 'eve'" class="tableEvent">
                <li>
                  <base-button type="" title="ดูรายละเอียด" style="margin-right: 5px;" icon size="sm" @click="VieStage(item)">
                        <i class="tim-icons icon-chart-pie-36"></i>
                    </base-button> 
                </li>
                <li>
                    <base-button type="warning" title="คัดลอกลิ้งค์" style="margin-right: 5px;" icon size="sm" v-clipboard:copy="item.short" v-clipboard:success="Clipboard">
                        <i class="tim-icons icon-single-copy-04"></i>
                    </base-button> 
                </li> 
                <!-- <li>
                    <base-button v-if="item.enable" type="danger" title="ปิดกันลิ้งค์" style="margin-right: 5px;" icon size="sm"  @click="update(item)">
                        <i class="tim-icons icon-lock-circle"></i>
                    </base-button>
                    <base-button v-if="!item.enable" type="success" title="เปิดใช้ลิ้งค์" style="margin-right: 5px;" icon size="sm" @click="update(item)">
                        <i class="tim-icons icon-link-72"></i>
                    </base-button>
                </li> -->
            </ul>
          </td> 
        </slot>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td>รวม</td>
        <td>{{ data.length }}</td>
        <td>คน</td>
      </tr>
      </tbody>
    </table>
  </template>
  <script>
    import utils from '@/utils';
    export default {
      name: 'table-list-af',
      props: {
        columns: {
          type: Array,
          default: () => [],
          description: "Table columns"
        },
        columnth: {
          type: Array,
          default: () => [],
          description: "Table columns"
        },
        data: {
          type: Array,
          default: () => [],
          description: "Table data"
        },
        type: {
          type: String, // striped | hover
          default: "",
          description: "Whether table is striped or hover type"
        },
        theadClasses: {
          type: String,
          default: '',
          description: "<thead> css classes"
        },
        tbodyClasses: {
          type: String,
          default: '',
          description: "<tbody> css classes"
        }
      },
      computed: {
        tableClass() {
          return this.type && `table-${this.type}`;
        }
      },
      methods: {
        formatDate(timestamp){
          let timeS = (timestamp)? new Date(timestamp) : new Date();
          let d = timeS,
              hour = d.getHours(),
              minutes = d.getMinutes(),
              seconds = d.getSeconds(),
              month = '' + (d.getMonth() + 1),
              day = '' + d.getDate(), 
              year = d.getFullYear();
          if (month.length < 2)
              month = '0' + month;
          if (day.length < 2)
              day = '0' + day;
          return `${[year, month, day].join('-')} ${hour}:${minutes}:${seconds}` ;
        },
        isValidDate(d) {
          return d instanceof Date && !isNaN(d);
        },
        hasValue(item, column) {
          return item[column] !== "undefined";
        },
        VieStage(item){ 
          this.$emit('VieStage',item);
        },
        Clipboard(){
            this.$alert('คัดลอกลิ้งเรียบร้อย','Coppy Link','success');
        },
        itemValue(item, column) {
          if(column == 'created_at' || column ==  'updated_at'){
            return utils.forMatDateTimes(new Date(item[column]));
          }else if(column == 'enable'){
            return (item[column])?`<span style="color: #00f2c3;">เปิดใช้งาน</span>`:`<span style="color: #ec250d">ปิดใช้งาน</span>`;
          }else if(column == 'ip'){
            if(item.ip == null){
              return item.account;
            }else{
              return item.ip;
            }
          }else if(column == 'name'){
            if(item.name == null){
              return `ผู้ชม`;
            }
            else{
              return `ผู้สมัคร`;
            }
          }else if(column == 'short'){
            return item[column];
          }else{
            return item[column] !== null && item[column] !== undefined ? item[column] : '-';
          }
          // if(this.isValidDate(new Date(item[column]))){
          //   return utils.forMatDateTimes(new Date(item[column]));
          // }else{
          //   return item[column];
          // }
  
        }
      }
    };
  </script>
  <style scoped>
    .tableEvent{
        margin: 0px;
        padding: 0;
    }
    .tableEvent > li{
        display: inline-block;
    }
  </style>
  