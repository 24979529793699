<template lang="">
    <div>
        <h1>report_members_promotion_free_view</h1>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>