<template lang="">
    <div class="row">
         <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="col-12">
            <card :title="table.title">
                <div class="row">
                    <div class="col-12">
                    <base-input addon-left-icon="tim-icons icon-zoom-split" v-model="searchName" v-on:change="searchWord()" placeholder="ค้นหาจากชื่อ ... <Enter>" v-on:keyup.enter="searchWord()">
                    </base-input>
                    </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                     <b-card class="text-center shadow-sm" body-class="d-flex align-items-center ">
                        <div class="icon-container">
                            <b-icon icon="person" variant="primary" font-scale="2"></b-icon>
                            </div>
                            <div class="content ml-3">
                            <p class="mb-0 text-muted">จำนวนลูกค้าที่หายไปเกิน 7 วัน</p>
                            <h4 class="mb-0 font-weight-bold">{{ allUser }}</h4>
                        </div>
                    </b-card>
                  </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <base-table-expired7d :data="table.data" :columns="table.columns" :columnth="table.columnsTH" :sort="table.sort" @SortTable="SortTable" :title="table.title" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <sliding-pagination :current="currentPage" :total="totalPages" @page-change="pageChangeHandler"></sliding-pagination>
                    </div>
                </div>
            </card>
        </div>  
    </div>
</template>
<script>
import { BaseTableExpired7d } from '@/components';
import SlidingPagination from 'vue-sliding-pagination'
import Loading from 'vue-loading-overlay';
import utils from '@/utils';
import { Datetime } from 'vue-datetime';
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";
import axios from 'axios';
import config from '@/config.js';
const tableColumns = [ "gameId","name", "numberBank", "created_at", "latestTransaction.event", "latestTransaction.created_at", "status", "deposit"];
const tableColumnsTH = [ "เกมไอดี","ชื่อผู้ใช้", "เลขบัญชี", "วันที่สมัคร", "รายการล่าสุด", "วันที่ทำรายการล่าสุด", "สถานะ", "ฝากรวม"];
const tableData = [];
export default {
    components: {
      Datetime,
      Loading,
      BaseTableExpired7d,
      SlidingPagination,
    },
    data() {
        return {
            fullPage: false,
            isLoading: true,
            table: {
                sort:{
                    even:'desc',
                    col:'created_at'
                },
                title: "รายงานลูกค้าหายไป 7 วัน",
                columns: [...tableColumns],
                columnsTH: [...tableColumnsTH],
                data: [...tableData]
            },
            searchName: '',
            currentPage: 1,
            totalPages: 1,
            allUser: 0
        }
    },
    methods: {
        LoadData() {
            if (utils.permission('REPORT_MEMBERS_EXPIRED7DAY_VIEW')) {
                axios(config.api('get', `/newReport/expired7day/1`)).then((response)=>{
                    console.log(response.data.ResultData.skipdata);
                    
                    this.table.data = response.data.ResultData.skipdata;
                    this.totalPages = response.data.ResultData.pages;
                    this.allUser = response.data.ResultData.all;
                    this.isLoading = false; 
            },(error)=>{
                this.isLoading = false;
                this.$alert(error.response.data.Message,'Error','error');
            });                
            } else {
                this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน', 'Permission', 'warning');
                this.isLoading = false;
            }
        },
        SortTable(column){ 
            // console.log('column',column);
            let sortType = (this.table.sort.col == column)?(this.table.sort.even == 'desc')?'aec':'desc':this.table.sort.even;
            let ObjK = {};
            ObjK[column] = this.table.data;
            this.table.sort.col = column;
            this.table.sort.even = sortType; 
            this.table.data = utils.Sort[sortType](ObjK) 

        },
        pageChangeHandler(selectedPage) {
            this.isLoading = true;
            axios(config.api('get', '/newReport/expired7day/'+selectedPage)).then((response)=>{
                this.table.data = response.data.ResultData.skipdata;
                this.currentPage = selectedPage;
                this.isLoading = false;
            },(error)=>{
                this.$alert(error.response.data.Message,'Error','error');
            });
        },
        searchWord() {
            if (this.searchName.length <= 0) {
                this.LoadData();
            }
            else{
                this.isLoading = true;
                axios(config.api('post', '/newReport/expired7day/search', { params: { keyword: this.searchName } })).then((response)=>{
                    this.table.data = response.data.ResultData.skipdata;
                    this.totalPages = 1;
                    this.isLoading = false;
                },(error)=>{
                    this.$alert(error.response.data.Message,'Error','error');
                });
            }
        },
    },
    mounted() {
        this.LoadData();        
    },
    computed: {
  },
}
</script>
<style scoped>
.row.align-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>